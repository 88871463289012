import { useRef, useState } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, useTexture, AccumulativeShadows, RandomizedLight, Decal, Environment, Center, Html } from '@react-three/drei';
import { easing } from 'maath';
import { useSnapshot } from 'valtio';
import { state } from './store';
import * as THREE from 'three';
import { AiFillPicture } from "react-icons/ai";


const SizeSVG = () => (
  <svg className='tamano' width="30" height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  <g fill="#010101">
  <path d="M87.69,32.38c6,2.54,8.22,7.04,8.04,13.52c-0.32,11.68-0.06,23.38-0.1,35.07c-0.03,7.74-4.15,11.87-11.94,11.91
       c-12.28,0.06-24.57,0.07-36.85-0.01c-7.43-0.05-11.66-4.29-11.71-11.75c-0.08-11.55,0.21-23.1-0.11-34.64
       c-0.18-6.53,1.83-11.32,7.89-14.11C57.83,32.38,72.76,32.38,87.69,32.38z"/>
   <path d="M414.38,115.25c-1.1,15.95-2,28.92-2.88,41.9c-0.43,6.36-3.06,11.32-9.27,13.59c-6.2,2.27-11.39,0.27-15.83-4.38
       c-3.63-3.81-7.53-7.36-11.11-11.21c-1.71-1.84-2.7-1.78-4.45,0c-7.87,8.05-15.9,15.93-23.85,23.9c-2.54,2.54-5.39,3.51-8.67,1.58
       c-2.72-1.6-3.55-4.13-2.99-7.15c0.37-2,1.89-3.24,3.22-4.57c7.44-7.44,14.76-15.01,22.41-22.24c2.65-2.5,2.23-3.85-0.16-6.02
       c-3.89-3.54-7.51-7.37-11.2-11.12c-4.28-4.34-5.8-9.44-3.55-15.23c2.17-5.58,6.56-8.47,12.44-8.91
       c13.41-1.01,26.82-2.04,40.24-2.76C408.65,102.08,414.67,108.34,414.38,115.25z"/>
   <path d="M483.77,428.92c0,15,0.01,30.01,0.01,45.01c-1.73,5.06-4.77,7.66-9.84,7.71c-13.16,0.12-26.33,0.15-39.49,0
       c-6.15-0.07-10.4-4.46-10.46-10.65c-0.11-13.01-0.11-26.02,0-39.03c0.05-6.19,4.23-10.62,10.41-10.7
       c13.16-0.19,26.33-0.15,39.49-0.01C479.04,421.3,481.99,423.85,483.77,428.92z"/>
   <path d="M475.96,33.1c4.04,0.8,6.74,3.05,7.76,7.14c0.03,15,0.07,30,0.1,45c-1.67,5.18-4.57,7.74-9.69,7.78
       c-13.28,0.12-26.56,0.14-39.84,0c-6-0.06-10.22-4.27-10.28-10.24c-0.14-13.27-0.15-26.55,0.02-39.82c0.06-5.02,2.78-8.43,7.79-9.76
       C446.53,33.17,461.25,33.13,475.96,33.1z"/>
  
       <path d="M309.54,367.79c-10.56,9.57-20.6,18.67-30.63,27.78c-3.9,3.54-7.76,7.12-11.69,10.62c-7.22,6.42-14.6,5.97-21-1.35
           c-21.84-25-43.67-50.01-65.47-75.05c-7.75-8.9-11.84-8.96-19.71,0.06c-28.76,32.94-57.42,65.96-86.33,98.77
           c-3.82,4.34-2.61,8.9-2.47,13.53c0.05,1.52,1.66,2.34,3.25,2.44c1.31,0.08,2.63,0.04,3.95,0.04c51.36,0,102.72,0,154.08,0
           c0.88,0,1.76,0,2.63,0c6.28,0.02,9.48,2.23,9.46,6.54c-0.02,4.32-3.29,6.62-9.49,6.62c-52.82,0-105.65,0.01-158.47,0
           c-12.34,0-18.87-6.59-18.87-18.99c-0.01-58.5,0-117,0.01-175.49c0-1.74-0.2-3.49,0.58-5.18c1.41-3.04,3.77-4.39,7.05-3.93
           c3.13,0.43,4.95,2.39,5.42,5.45c0.22,1.43,0.1,2.92,0.1,4.38c0,47.38,0,94.77,0,142.15c0,1.54,0,3.09,0,5.71
           c7.11-8.13,13.53-15.44,19.92-22.77c20.48-23.47,40.93-46.96,61.42-70.43c10.03-11.49,24.9-11.66,35.08-0.09
           c21.83,24.82,43.48,49.79,65.2,74.7c3.65,4.18,3.63,4.15,7.72,0.44c15.17-13.76,30.31-27.54,45.57-41.19
           c2.16-1.94,2.96-3.88,2.95-6.75c-0.08-44.02-0.06-88.04-0.06-132.06c0-6.44-0.27-6.71-6.68-6.71c-74.77,0-149.55,0-224.32,0
           c-6.63,0-6.79,0.17-6.8,7.02c0,4.97,0.05,9.95-0.02,14.92c-0.07,4.57-2.45,7.27-6.34,7.37c-4.01,0.1-6.72-2.76-6.79-7.43
           c-0.09-6.14-0.13-12.29,0.01-18.43c0.21-9.61,7.18-16.57,16.77-16.58c76.82-0.04,153.65-0.04,230.47,0
           c9.94,0.01,16.81,7.15,16.85,17.41c0.07,19.16,0.02,38.32,0.02,57.47c0,56.45,0,112.9,0,169.35c0,13.29-6.33,19.67-19.54,19.67
           c-10.83,0-21.66,0.07-32.48-0.03c-5.63-0.05-9.02-4.16-7.35-8.73c1.22-3.32,3.87-4.44,7.29-4.42c10.83,0.06,21.66,0.03,32.48,0.02
           c5.72-0.01,6.42-0.67,6.42-6.17c0.01-22.96,0-45.92,0-68.88C309.75,369.28,309.68,368.99,309.54,367.79z"/>
       <path d="M232.51,278.18c0.12-13.52,11.27-24.36,24.83-24.15c13.54,0.22,24.51,11.44,24.27,24.83
           c-0.24,13.5-11.44,24.34-24.95,24.15C243.06,302.81,232.39,291.84,232.51,278.18z"/>
  
   <path class="st0" d="M460.77,123.1c0,1.75,0.13,3.5-0.03,5.24c-0.37,3.9-3.39,6.62-6.93,6.4c-3.5-0.22-5.99-2.82-6.14-6.67
       c-0.13-3.2-0.1-6.4-0.01-9.6c0.11-4.09,2.63-6.87,6.21-7.04c3.79-0.18,6.63,2.61,6.91,6.87c0.1,1.59,0.02,3.2,0.02,4.8
       C460.78,123.1,460.77,123.1,460.77,123.1z"/>
   <path class="st0" d="M71.92,122.88c0,1.89,0.11,3.79-0.03,5.67c-0.28,3.77-2.59,5.91-6.18,6.11c-3.66,0.2-6.31-1.92-6.72-5.52
       c-0.45-4.02-0.47-8.17,0.01-12.19c0.42-3.57,3.05-5.7,6.75-5.47c3.63,0.23,5.84,2.42,6.15,6.15
       C72.04,119.37,71.92,121.13,71.92,122.88z"/>
   <path class="st0" d="M304.2,69.34c-1.89,0-3.8,0.19-5.66-0.04c-3.29-0.4-5.22-2.41-5.51-5.72c-0.31-3.5,1.25-6.12,4.69-6.84
       c4.42-0.93,8.99-0.86,13.41-0.06c3.43,0.61,5.16,3.16,4.92,6.68c-0.24,3.51-2.25,5.61-5.75,5.97c-2.01,0.21-4.06,0.04-6.1,0.04
       C304.2,69.36,304.2,69.35,304.2,69.34z"/>
   <path class="st0" d="M259.72,69.37c-1.89,0-3.79,0.16-5.66-0.03c-3.5-0.36-5.54-2.41-5.79-5.93c-0.25-3.52,1.44-6.05,4.87-6.72
       c4.28-0.84,8.71-0.76,13-0.07c3.63,0.59,5.47,3.19,5.15,6.93c-0.31,3.52-2.4,5.51-5.91,5.81c-1.87,0.16-3.78,0.03-5.66,0.03
       C259.72,69.38,259.72,69.37,259.72,69.37z"/>
   <path class="st0" d="M71.92,168.06c0,1.75,0.12,3.5-0.03,5.23c-0.33,3.75-2.59,5.88-6.22,6.05c-3.69,0.17-6.29-1.96-6.68-5.55
       c-0.44-4.02-0.47-8.17,0.03-12.18c0.44-3.55,3.05-5.67,6.78-5.41c3.66,0.26,5.81,2.47,6.1,6.19
       C72.04,164.27,71.92,166.17,71.92,168.06z"/>
   <path class="st0" d="M214.75,69.35c-1.89,0-3.8,0.18-5.66-0.04c-3.3-0.39-5.23-2.41-5.55-5.7c-0.34-3.5,1.22-6.15,4.67-6.85
       c4.56-0.92,9.28-0.99,13.82,0.05c3.45,0.78,4.89,3.49,4.49,6.96c-0.38,3.31-2.37,5.24-5.68,5.56c-2.02,0.19-4.06,0.04-6.1,0.04
       C214.75,69.36,214.75,69.35,214.75,69.35z"/>
   <path class="st0" d="M447.65,212.41c0-1.74-0.1-3.5,0.02-5.23c0.26-3.72,2.37-5.86,6.03-6.26c3.48-0.38,6.63,2.09,6.95,5.76
       c0.32,3.75,0.3,7.57,0.04,11.33c-0.25,3.72-3.23,6.19-6.71,6.09c-3.57-0.1-6.07-2.6-6.32-6.45
       C447.55,215.9,447.65,214.15,447.65,212.41z"/>
   <path class="st0" d="M447.66,257.01c0-1.74-0.13-3.5,0.02-5.23c0.32-3.7,2.42-5.89,6.12-6.13c3.77-0.24,6.32,1.87,6.76,5.42
       c0.49,4.01,0.47,8.16,0.02,12.17c-0.41,3.57-2.95,5.67-6.7,5.48c-3.7-0.18-5.86-2.32-6.18-6.04
       C447.53,260.8,447.66,258.9,447.66,257.01z"/>
   <path class="st0" d="M170.32,69.37c-1.89,0-3.79,0.14-5.66-0.03c-3.51-0.33-5.58-2.38-5.86-5.88c-0.3-3.74,1.61-6.28,5.23-6.88
       c4.15-0.69,8.43-0.68,12.58-0.01c3.62,0.59,5.51,3.14,5.22,6.89c-0.27,3.52-2.33,5.56-5.85,5.88
       C174.11,69.52,172.2,69.38,170.32,69.37z"/>
   <path class="st0" d="M447.65,301.84c0-1.74-0.11-3.5,0.02-5.23c0.27-3.69,2.3-5.89,6.01-6.25c3.54-0.35,6.67,2.03,6.97,5.71
       c0.32,3.9,0.33,7.86-0.01,11.75c-0.32,3.65-3.5,5.97-7.06,5.58c-3.72-0.4-5.68-2.63-5.91-6.33
       C447.56,305.34,447.65,303.59,447.65,301.84z"/>
   <path class="st0" d="M447.66,346.29c0-1.74-0.13-3.5,0.03-5.23c0.33-3.48,2.3-5.61,5.81-5.96c3.75-0.38,6.49,1.54,6.98,5.09
       c0.56,4.13,0.55,8.44,0.06,12.59c-0.42,3.56-3.02,5.59-6.8,5.33c-3.73-0.26-5.78-2.46-6.06-6.15
       C447.53,350.08,447.65,348.18,447.66,346.29z"/>
   <path class="st0" d="M393.93,69.38c-1.89-0.01-3.79,0.14-5.66-0.04c-3.54-0.33-5.55-2.44-5.8-5.93c-0.25-3.49,1.43-6.09,4.87-6.72
       c4.43-0.81,9-0.86,13.42,0.04c3.43,0.7,5.1,3.31,4.77,6.79c-0.33,3.47-2.41,5.53-5.93,5.83C397.72,69.51,395.82,69.38,393.93,69.38
       z"/>
   <path class="st0" d="M349.11,69.36c-1.89,0-3.79,0.16-5.66-0.03c-3.53-0.37-5.49-2.5-5.71-6c-0.22-3.53,1.52-6.04,4.95-6.67
       c4.29-0.79,8.72-0.77,13-0.02c3.63,0.63,5.46,3.26,5.08,6.98c-0.35,3.48-2.48,5.48-5.99,5.75c-1.88,0.15-3.78,0.03-5.66,0.03
       C349.11,69.38,349.11,69.37,349.11,69.36z"/>
   <path class="st0" d="M447.66,167.52c0-1.75-0.13-3.5,0.03-5.23c0.34-3.72,2.53-5.89,6.19-6.09c3.74-0.21,6.29,1.96,6.7,5.51
       c0.47,4.01,0.46,8.17-0.01,12.18c-0.43,3.58-3.07,5.67-6.76,5.44c-3.63-0.23-5.86-2.38-6.12-6.14
       C447.55,171.31,447.66,169.41,447.66,167.52z"/>
   <path class="st0" d="M125.59,69.38c-1.89,0-3.79,0.14-5.66-0.03c-3.52-0.32-5.58-2.4-5.88-5.87c-0.31-3.49,1.38-6.08,4.81-6.76
       c4.42-0.88,8.99-0.86,13.42-0.02c3.44,0.66,5.1,3.26,4.83,6.75c-0.27,3.5-2.31,5.58-5.84,5.89
       C129.39,69.51,127.48,69.37,125.59,69.38z"/>
   <path class="st0" d="M447.65,391.32c0-1.74-0.1-3.49,0.02-5.23c0.25-3.68,2.22-5.92,5.95-6.28c3.58-0.35,6.71,1.96,7.02,5.63
       c0.33,3.89,0.33,7.86,0,11.75c-0.31,3.66-3.44,5.99-7.02,5.64c-3.72-0.37-5.71-2.58-5.96-6.28
       C447.55,394.82,447.65,393.06,447.65,391.32z"/>
   <path class="st0" d="M349.54,444.9c1.74,0,3.5-0.14,5.23,0.03c3.69,0.36,6.21,3.17,6.04,6.7c-0.18,3.74-2.33,5.99-5.96,6.29
       c-3.61,0.3-7.27,0.23-10.9,0.04c-3.78-0.19-6.25-2.9-6.27-6.47c-0.02-3.55,2.45-6.26,6.19-6.57
       C345.75,444.77,347.65,444.89,349.54,444.9z"/>
   <path d="M393.69,457.96c-1.89,0-3.82,0.24-5.66-0.05c-3.41-0.55-5.47-2.68-5.58-6.21c-0.11-3.74,1.93-6.26,5.53-6.66
       c3.88-0.44,7.87-0.39,11.76-0.04c3.6,0.33,5.73,2.68,5.84,6.43c0.1,3.5-2.5,6.24-6.21,6.55c-1.88,0.16-3.78,0.03-5.67,0.03
       C393.69,457.99,393.69,457.97,393.69,457.96z"/>
 </g>
</svg>


);


const HorizontalSVG = () => (
 <svg className='tamano' width="30" height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
<svg >

	<path d="M401.72,359.16c-13.38,10.43-26.11,20.35-38.84,30.27c-4.94,3.86-9.84,7.76-14.83,11.57c-9.16,7-18.51,6.5-26.62-1.48
		c-27.69-27.24-55.37-54.5-83.01-81.79c-9.83-9.7-15.01-9.76-24.99,0.06c-36.46,35.9-72.81,71.88-109.46,107.64
		c-4.85,4.73-3.31,9.7-3.13,14.75c0.06,1.66,2.1,2.55,4.12,2.66c1.66,0.09,3.34,0.04,5.01,0.04c65.12,0,130.24,0,195.36,0
		c1.11,0,2.23,0,3.34,0c7.96,0.02,12.02,2.43,12,7.13c-0.02,4.71-4.17,7.21-12.03,7.21c-66.98,0-133.95,0.01-200.93,0
		c-15.64,0-23.93-7.18-23.93-20.7c-0.01-63.75-0.01-127.51,0.01-191.26c0-1.89-0.26-3.81,0.73-5.64c1.79-3.32,4.77-4.78,8.94-4.29
		c3.97,0.47,6.27,2.6,6.88,5.94c0.28,1.56,0.13,3.18,0.13,4.77c0,51.64,0,103.28,0,154.92c0,1.68,0,3.36,0,6.22
		c9.02-8.86,17.15-16.82,25.26-24.81c25.96-25.58,51.9-51.18,77.87-76.76c12.72-12.52,31.57-12.71,44.47-0.1
		c27.68,27.05,55.13,54.26,82.67,81.41c4.63,4.56,4.6,4.52,9.79,0.48c19.23-14.99,38.43-30.02,57.78-44.89
		c2.74-2.11,3.75-4.23,3.75-7.35c-0.11-47.97-0.07-95.95-0.08-143.92c0-7.02-0.34-7.31-8.47-7.31c-94.81,0-189.61,0-284.42,0
		c-8.41,0-8.61,0.19-8.62,7.65c0,5.42,0.07,10.84-0.02,16.26c-0.08,4.98-3.11,7.92-8.04,8.03c-5.08,0.11-8.52-3.01-8.61-8.1
		c-0.11-6.69-0.16-13.39,0.01-20.08c0.27-10.47,9.11-18.06,21.27-18.07c97.4-0.05,194.81-0.05,292.21,0
		c12.6,0.01,21.32,7.79,21.36,18.97c0.09,20.88,0.03,41.76,0.03,62.64c0,61.52,0,123.04,0,184.57c0,14.49-8.03,21.43-24.77,21.44
		c-13.73,0-27.46,0.08-41.19-0.03c-7.14-0.06-11.44-4.54-9.31-9.51c1.55-3.62,4.9-4.84,9.24-4.82c13.73,0.07,27.46,0.03,41.19,0.02
		c7.25-0.01,8.14-0.73,8.14-6.72c0.01-25.02,0-50.05-0.01-75.07C401.98,360.79,401.9,360.47,401.72,359.16z"/>
	<path d="M304.05,261.5c0.15-14.74,14.28-26.55,31.49-26.31c17.16,0.24,31.07,12.47,30.77,27.06c-0.3,14.71-14.51,26.53-31.64,26.32
		C317.43,288.35,303.9,276.4,304.05,261.5z"/>

	<path d="M223.53,84.99H126.9c-0.28,0-0.56,0.01-0.83,0.03c0.02-1.52,0.04-2.76,0.07-3.42c0.22-4.45,0.11-8.92,0.09-13.38
		c-0.02-5.17-2.16-9.14-6.96-11.27c-4.64-2.06-9-1.17-12.8,2.08c-8.66,7.4-17.33,14.8-25.84,22.38c-6.28,5.61-6.16,12.97-1.85,16.95
		c10.19,8.93,18.48,16.19,26.76,23.46c4.06,3.57,8.6,4.97,13.69,2.63c5.08-2.34,7-6.64,6.89-12.09c-0.09-4.46,0.13-8.92-0.02-13.38
		c-0.02-0.51-0.03-1.61-0.04-3.02c0.28,0.02,0.57,0.03,0.85,0.03l96.63-0.11c4.52-0.01,8.17-2.44,8.17-5.44
		C231.71,87.43,228.05,84.99,223.53,84.99z"/>
	<path d="M278.9,84.99h96.63c0.28,0,0.56,0.01,0.83,0.03c-0.02-1.52-0.04-2.76-0.07-3.42c-0.22-4.45-0.11-8.92-0.09-13.38
		c0.02-5.17,2.16-9.14,6.96-11.27c4.64-2.06,9-1.17,12.8,2.08c8.66,7.4,17.33,14.8,25.84,22.38c6.28,5.61,6.16,12.97,1.85,16.95
		c-10.19,8.93-18.48,16.19-26.76,23.46c-4.06,3.57-8.6,4.97-13.69,2.63c-5.08-2.34-7-6.64-6.89-12.09
		c0.09-4.46-0.13-8.92,0.02-13.38c0.02-0.51,0.03-1.61,0.04-3.02c-0.28,0.02-0.57,0.03-0.85,0.03l-96.63-0.11
		c-4.52-0.01-8.17-2.44-8.17-5.44C270.71,87.43,274.38,84.99,278.9,84.99z"/>

</svg>

</svg>
);
const VerticalSVG = () => (
  <svg className='tamano' width="30" height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
  <path d="M340.02,318.65c-12.33,11.52-24.05,22.47-35.77,33.43c-4.55,4.26-9.06,8.57-13.66,12.78c-8.43,7.73-17.05,7.18-24.52-1.63
           c-25.51-30.09-50.99-60.19-76.45-90.32c-9.05-10.71-13.82-10.78-23.01,0.07c-33.58,39.64-67.05,79.38-100.8,118.87
           c-4.47,5.22-3.05,10.71-2.88,16.29c0.05,1.83,1.93,2.81,3.8,2.93c1.53,0.1,3.07,0.05,4.61,0.05c59.97,0,119.95,0,179.92,0
           c1.03,0,2.05,0,3.08,0c7.33,0.02,11.07,2.69,11.05,7.87c-0.02,5.2-3.84,7.96-11.08,7.97c-61.68,0.01-123.36,0.01-185.04,0
           c-14.4,0-22.04-7.93-22.04-22.86c-0.01-70.4-0.01-140.8,0.01-211.21c0-2.09-0.24-4.2,0.68-6.23c1.65-3.66,4.4-5.28,8.23-4.73
           c3.66,0.52,5.78,2.87,6.33,6.56c0.26,1.72,0.12,3.51,0.12,5.27c0,57.03,0,114.05,0,171.08c0,1.86,0,3.71,0,6.87
           c8.31-9.78,15.8-18.58,23.26-27.4c23.91-28.25,47.8-56.52,71.71-84.76c11.71-13.83,29.08-14.03,40.96-0.11
           c25.49,29.87,50.77,59.92,76.14,89.9c4.26,5.03,4.24,5,9.02,0.53c17.71-16.56,35.39-33.15,53.21-49.58
           c2.53-2.33,3.46-4.67,3.45-8.12c-0.1-52.98-0.07-105.95-0.07-158.93c0-7.75-0.31-8.07-7.8-8.07c-87.31,0-174.62,0-261.93,0
           c-7.74,0-7.93,0.21-7.94,8.44c0,5.98,0.06,11.97-0.02,17.95c-0.08,5.5-2.86,8.74-7.4,8.86c-4.68,0.12-7.85-3.32-7.93-8.95
           c-0.1-7.39-0.15-14.79,0.01-22.18c0.25-11.57,8.39-19.95,19.59-19.95c89.7-0.05,179.41-0.05,269.11,0
           c11.6,0.01,19.63,8.61,19.67,20.95c0.08,23.06,0.02,46.11,0.02,69.17c0,67.94,0,135.88,0,203.81c0,16-7.4,23.67-22.81,23.68
           c-12.64,0-25.29,0.09-37.93-0.04c-6.57-0.06-10.53-5.01-8.58-10.51c1.42-3.99,4.51-5.35,8.51-5.32
           c12.64,0.08,25.29,0.03,37.93,0.02c6.67-0.01,7.49-0.81,7.49-7.42c0.01-27.63,0-55.27,0-82.9
           C340.26,320.44,340.18,320.09,340.02,318.65z"/>
       <path d="M250.07,210.8c0.14-16.28,13.16-29.32,29-29.06c15.81,0.26,28.62,13.77,28.34,29.88c-0.28,16.24-13.36,29.29-29.14,29.06
           C262.39,240.45,249.93,227.25,250.07,210.8z"/>
      
           <path d="M410.17,247.5l-0.17-101.92c0-0.29-0.01-0.59-0.03-0.87c1.39,0.01,2.52,0.04,3.12,0.07c4.06,0.23,8.14,0.09,12.21,0.07
               c4.72-0.03,8.34-2.29,10.28-7.37c1.87-4.9,1.05-9.5-1.92-13.49c-6.77-9.12-13.54-18.25-20.48-27.2
               c-5.13-6.62-11.85-6.46-15.48-1.92c-8.14,10.77-14.74,19.53-21.37,28.27c-3.25,4.29-4.52,9.08-2.37,14.44
               c2.14,5.35,6.08,7.37,11.05,7.24c4.07-0.11,8.14,0.12,12.21-0.05c0.46-0.02,1.47-0.04,2.76-0.05c-0.02,0.3-0.03,0.6-0.02,0.9
               l0.28,101.92c0.01,4.76,2.24,8.61,4.98,8.61C407.96,256.14,410.18,252.27,410.17,247.5z"/>
           <path d="M410.27,290.08L410.44,392c0,0.29-0.01,0.59-0.02,0.87c1.39-0.02,2.52-0.05,3.12-0.08c4.06-0.24,8.14-0.13,12.21-0.12
               c4.72,0.01,8.35,2.25,10.3,7.32c1.89,4.89,1.08,9.49-1.87,13.5c-6.74,9.15-13.48,18.31-20.39,27.3
               c-5.11,6.64-11.83,6.52-15.47,1.99c-8.17-10.73-14.81-19.46-21.46-28.18c-3.26-4.27-4.55-9.06-2.42-14.43
               c2.13-5.36,6.05-7.4,11.02-7.29c4.07,0.09,8.14-0.15,12.21,0c0.46,0.02,1.47,0.03,2.76,0.04c-0.02-0.3-0.03-0.6-0.03-0.9
               l-0.07-101.92c0-4.76,2.21-8.62,4.95-8.63C408.03,281.45,410.26,285.31,410.27,290.08z"/>
   </svg>
);
const DepthSVG = () => (

  <svg className='tamano' width="30" height="30" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">

	<path d="M47,254.54c6.57-11.82,13.26-23.57,19.68-35.47c20.49-37.99,40.88-76.02,61.28-114.06c1.41-2.63,3.27-5.39,6.33-4.59
		c2.36,0.62,5.04,2.71,6.18,4.87c21.99,41.58,43.78,83.28,65.62,124.94c3.93,7.51,7.83,15.04,11.96,22.44
		c1.98,3.54,1.91,6.59-0.03,10.19c-12.97,24.18-25.77,48.46-38.62,72.71c-0.69,1.3-1.37,2.61-2.07,3.96
		c1.94,0.31,4.14,0.09,5.54,1.04c1.49,1,2.72,2.99,3.2,4.77c0.8,2.98-0.74,5.27-3.61,6.37c-4.73,1.82-6.78-1.59-9.11-5.42
		c-11.28,21.2-22.34,41.95-33.34,62.73c-1.5,2.84-3.04,5.56-6.75,5.48c-3.49-0.07-4.95-2.68-6.39-5.39
		c-17.37-32.81-34.79-65.6-52.19-98.4c-0.63-1.19-1.39-2.37-1.7-3.64c-0.7-2.9,0.11-5.46,2.85-6.89c2.82-1.47,5.54-1.01,7.6,1.54
		c0.92,1.14,1.58,2.5,2.27,3.8c15.07,28.4,30.13,56.81,45.19,85.21c0.69,1.3,1.39,2.59,2.39,4.44c0.81-1.31,1.45-2.22,1.98-3.2
		c6.82-12.83,13.6-25.68,20.45-38.49c16.41-30.72,32.82-61.45,49.36-92.1c1.48-2.74,1.35-4.71-0.07-7.37
		c-19.82-37.15-39.56-74.34-59.23-111.57c-3.31-6.27-6.22-12.75-9.35-19.12c-0.57-1.16-1.33-2.22-2.29-3.82
		c-4.61,8.62-8.98,16.75-13.31,24.91c-5.62,10.57-11.13,21.2-16.81,31.74c-13.13,24.38-25.96,48.93-39.77,72.92
		c-3.59,6.24-3.71,10.6-0.02,16.18c2.66,4.02,4.68,8.47,6.89,12.78c2.04,3.95,1.29,7.45-1.83,9.17c-3.32,1.83-6.68,0.51-8.92-3.55
		c-4.46-8.08-8.91-16.17-13.36-24.25C47,257.77,47,256.15,47,254.54z"/>
	<path d="M294.07,119.33c-6.8,12.82-13.36,25.18-19.91,37.53c-0.44,0.83-0.87,1.67-1.34,2.49c-2.12,3.64-5.24,4.82-8.39,3.19
		c-3.33-1.72-4.23-5.1-2.22-9.02c2.89-5.63,5.9-11.19,8.86-16.77c5.55-10.46,11.15-20.89,16.64-31.38c1.45-2.77,3.24-5.72,6.51-4.93
		c2.35,0.57,4.98,2.72,6.16,4.89c10.8,19.86,21.31,39.87,31.91,59.84c0.56,1.06,1.15,2.1,1.96,3.58c0.87-1.51,1.59-2.67,2.23-3.88
		c10.48-19.72,20.95-39.45,31.41-59.19c1.34-2.52,2.5-4.95,6.13-4.71c3.27,0.21,5.14,1.89,6.56,4.56
		c26.25,49.27,52.5,98.55,78.82,147.79c1.72,3.21,1.59,5.95-0.11,9.1c-6.85,12.66-13.45,25.46-20.3,38.12
		c-9.07,16.79-18.28,33.51-27.44,50.25c-2.13,3.89-5.38,5.2-8.63,3.55c-3.31-1.68-4.17-5.29-1.97-9.37
		c11.18-20.7,22.45-41.35,33.65-62.04c3.84-7.1,7.44-14.34,11.43-21.35c1.59-2.79,1.44-4.93-0.03-7.7
		c-21.97-41.2-43.83-82.45-65.72-123.69c-1.81-3.4-3.62-6.81-5.77-10.87c-2.22,4.12-4.13,7.61-5.99,11.13
		c-8.63,16.29-17.21,32.61-25.94,48.84c-1.11,2.06-1.02,3.57,0.06,5.51c3.32,6.01,6.48,12.1,9.67,18.18
		c2.34,4.46,1.73,8.02-1.63,9.81c-3.44,1.84-6.67,0.44-9.06-4.05c-15.01-28.29-30-56.59-45-84.88
		C295.88,122.44,295.06,121.08,294.07,119.33z"/>
	<path d="M373.83,395.99c4.14-7.78,8.03-15.11,11.93-22.44c0.63-1.19,1.21-2.41,1.91-3.56c1.93-3.17,5.32-4.3,8.27-2.81
		c2.96,1.49,4.09,4.96,2.5,8.33c-1.38,2.92-3.01,5.71-4.53,8.56c-4.66,8.8-9.34,17.58-13.95,26.41c-1.38,2.65-3.06,4.86-6.34,4.83
		c-3.29-0.03-4.89-2.32-6.25-4.96c-10.21-19.82-20.46-39.61-30.7-59.41c-0.68-1.31-1.41-2.59-2.34-4.31
		c-0.81,1.49-1.45,2.63-2.07,3.79c-10.53,19.85-21.07,39.69-31.57,59.55c-1.43,2.71-2.94,5.29-6.44,5.34
		c-3.5,0.05-5.07-2.41-6.52-5.17c-8.33-15.83-16.73-31.64-25.16-47.42c-1.42-2.67-2.2-5.26-0.26-7.93c2.77-3.8,8.01-3.17,10.52,1.43
		c5.15,9.45,10.12,19,15.16,28.5c1.95,3.67,3.91,7.33,6.09,11.41c0.84-1.49,1.52-2.62,2.13-3.79
		c23.21-43.73,46.39-87.48,69.68-131.16c1.39-2.61,1.28-4.53-0.13-7.03c-3.57-6.32-6.88-12.8-10.23-19.24
		c-2.17-4.17-1.36-7.65,2.03-9.35c3.25-1.63,6.49-0.37,8.62,3.56c4.28,7.93,8.34,15.99,12.72,23.86c1.93,3.47,1.68,6.42-0.15,9.84
		c-11.96,22.29-23.73,44.67-35.68,66.96c-1.4,2.61-1.54,4.64-0.05,7.35c6.67,12.15,13.13,24.41,19.53,36.7
		c3.27,6.29,6.25,12.73,9.39,19.09C372.38,393.84,373,394.67,373.83,395.99z"/>
	<path d="M254.44,171.36c0.1,3.3-2.78,6.26-6.05,6.24c-3.22-0.02-6.31-3.19-6.42-6.57c-0.1-3.31,2.74-6.21,6.07-6.2
		C251.32,164.83,254.34,167.91,254.44,171.36z"/>
	<path d="M254.44,344.1c-0.05,3.44-3.03,6.56-6.32,6.62c-3.33,0.06-6.21-2.79-6.16-6.11c0.05-3.37,3.11-6.58,6.34-6.66
		C251.57,337.87,254.49,340.8,254.44,344.1z"/>
	<path d="M206.43,131.42c-0.05,3.75-3.11,6.84-6.63,6.7c-3.25-0.13-5.76-2.81-5.71-6.1c0.06-3.66,3.24-6.89,6.66-6.74
		C203.94,125.4,206.47,128.14,206.43,131.42z"/>
	<path d="M300.09,257.94c-0.09,3.6-3.03,6.42-6.5,6.24c-3.36-0.18-5.75-2.94-5.67-6.56c0.07-3.6,2.64-6.29,5.98-6.26
		C297.37,251.39,300.18,254.37,300.09,257.94z"/>
	<path d="M242.88,149.89c-0.01,3.28-2.52,5.9-5.76,6c-3.5,0.11-6.61-3.05-6.6-6.69c0.01-3.33,2.93-6.18,6.2-6.06
		C240.04,143.27,242.89,146.39,242.88,149.89z"/>
	<path d="M236.38,372.39c-3.32-0.1-6.03-3.07-5.84-6.41c0.2-3.51,3.19-6.41,6.54-6.34c3.39,0.07,5.95,2.88,5.78,6.36
		C242.69,369.52,239.68,372.49,236.38,372.39z"/>
	<path d="M197.47,367.33c-0.02,3.41-2.83,6.14-6.17,6.01c-3.32-0.13-6.14-3.24-6.12-6.74c0.02-3.39,2.87-6.17,6.18-6.03
		C194.66,360.7,197.49,363.84,197.47,367.33z"/>
	<path d="M259.76,199.18c-3.33-0.08-6.26-3.17-6.29-6.63c-0.03-3.39,2.73-6.08,6.17-6.01c3.35,0.07,6.27,3.11,6.31,6.57
		C265.98,196.41,263.08,199.26,259.76,199.18z"/>
	<path d="M288.8,236.34c0.05,3.43-2.66,6.15-6.05,6.1c-3.39-0.05-6.19-3-6.24-6.56c-0.04-3.42,2.7-6.24,6.03-6.18
		C285.81,229.76,288.75,232.88,288.8,236.34z"/>
	<path d="M276.52,279.3c0.24-3.57,3.2-6.34,6.6-6.18c3.4,0.16,5.89,3,5.66,6.46c-0.24,3.49-3.35,6.43-6.62,6.25
		C278.8,285.65,276.29,282.74,276.52,279.3z"/>
	<path d="M259.77,316.36c3.32-0.07,6.22,2.78,6.18,6.09c-0.04,3.46-2.96,6.49-6.32,6.55c-3.44,0.06-6.2-2.63-6.16-6.02
		C253.51,319.52,256.44,316.43,259.77,316.36z"/>
	<path d="M213.9,100.19c3.39,0.08,6.26,3.26,6.09,6.72c-0.17,3.37-3.01,5.87-6.51,5.72c-3.53-0.15-6-2.83-5.83-6.34
		C207.81,102.9,210.67,100.11,213.9,100.19z"/>
	<path d="M231.41,387.18c0.06,3.69-2.92,6.84-6.44,6.78c-3.28-0.05-5.78-2.57-5.84-5.88c-0.07-3.75,2.83-6.78,6.43-6.73
		C228.86,381.39,231.35,383.87,231.41,387.18z"/>
	<path d="M219.13,127.29c0.15-3.28,2.76-5.76,6.02-5.71c3.52,0.05,6.41,3.27,6.25,6.96c-0.15,3.43-2.94,5.89-6.42,5.64
		C221.6,133.94,218.97,130.84,219.13,127.29z"/>
	<path d="M277.39,214.62c0.1,3.21-2.39,5.92-5.62,6.11c-3.63,0.21-6.62-2.65-6.72-6.42c-0.08-3.37,2.25-5.95,5.55-6.13
		C274.11,207.98,277.27,210.98,277.39,214.62z"/>
	<path d="M271.38,294.79c3.4,0,6.12,2.78,6.01,6.14c-0.11,3.48-3.04,6.42-6.4,6.43c-3.49,0.01-6.03-2.61-5.94-6.14
		C265.14,297.66,267.96,294.79,271.38,294.79z"/>
	<path d="M193.01,156.91c-0.05,3.49-3.09,6.43-6.52,6.28c-3.2-0.14-5.81-2.88-5.78-6.07c0.04-3.52,3.01-6.37,6.52-6.24
		C190.49,151,193.05,153.68,193.01,156.91z"/>
	<path d="M213.9,414.54c-3.39,0.07-6.29-2.92-6.26-6.47c0.02-3.15,2.45-5.82,5.38-5.91c2.98-0.09,6.83,3.29,6.97,6.1
		C220.13,411.34,217.09,414.48,213.9,414.54z"/>
	<path d="M203.05,393.61c-3.78,0.01-6.7-2.91-6.63-6.61c0.05-2.74,3.05-5.35,6.05-5.28c3.3,0.08,6.25,3.25,6.25,6.72
		C208.72,391.84,206.79,393.61,203.05,393.61z"/>




 </svg>
);


export const App = ({ position = [0, 0, 2], fov = 25 }) => (
 <Canvas shadows camera={{ position, fov }} gl={{ preserveDrawingBuffer: true }} eventSource={document.getElementById('root')} eventPrefix="client">
   <ambientLight intensity={0.5} />
   <Environment files="https://dl.polyhaven.org/file/ph-assets/HDRIs/hdr/1k/potsdamer_platz_1k.hdr" />
   <CameraRig>
     <Backdrop />
     <Center>
       <Shirt />
     </Center>
   </CameraRig>
 </Canvas>
);


function Backdrop() {
 const shadows = useRef();
 useFrame((state, delta) => easing.dampC(shadows.current.getMesh().material.color, state.color, 0.25, delta));
 return (
   <AccumulativeShadows ref={shadows} temporal frames={60} alphaTest={0.85} scale={10} rotation={[Math.PI / 2, 0, 0]} position={[0, 0, -0.14]}>
     <RandomizedLight amount={4} radius={9} intensity={0.55} ambient={0.25} position={[5, 5, -10]} />
     <RandomizedLight amount={4} radius={5} intensity={0.25} ambient={0.55} position={[-5, 5, -9]} />
   </AccumulativeShadows>
 );
}


function CameraRig({ children }) {
 const group = useRef();
 const snap = useSnapshot(state);
 useFrame((state, delta) => {
   easing.damp3(state.camera.position, [snap.intro ? -state.viewport.width / 4 : 0, 0, 2], 0.25, delta);
   easing.dampE(group.current.rotation, [state.pointer.y / 10, -state.pointer.x / 5, 0], 0.25, delta);
 });
 return <group ref={group}>{children}</group>;
}


function Shirt(props) {
 const snap = useSnapshot(state);
 const [decalTexture, setDecalTexture] = useState(null);
 const { nodes, materials } = useGLTF('/shirt_baked_collapsed.glb');
 const [decalScale, setDecalScale] = useState(0.15);
 const [decalPosition, setDecalPosition] = useState([0, 0.04, 0.15]);
 const [visibleControl, setVisibleControl] = useState(null);


 useFrame((state, delta) => easing.dampC(materials.lambert1.color, snap.color, 0.25, delta));


 const handleScaleChange = (event) => {
   const value = parseFloat(event.target.value);
   setDecalScale(value);
 };


 const handlePositionChange = (axis, event) => {
   const newPosition = [...decalPosition];
   newPosition[axis] = parseFloat(event.target.value);
   setDecalPosition(newPosition);
 };


 const toggleVisibility = (control) => {
   setVisibleControl(visibleControl === control ? null : control);
 };


 const handleImageUpload = () => {
   const input = document.createElement('input');
   input.type = 'file';
   input.accept = 'image/*';
   input.onchange = (event) => {
     const file = event.target.files[0];
     if (file) {
       const reader = new FileReader();
       reader.onload = (e) => {
         const img = new Image();
         img.src = e.target.result;
         img.onload = () => {
           const texture = new THREE.Texture(img);
           texture.needsUpdate = true;
           setDecalTexture(texture);
         };
       };
       reader.readAsDataURL(file);
     }
   };
   input.click();
 };


 return (
   <>
     <mesh castShadow geometry={nodes.T_Shirt_male.geometry} material={materials.lambert1} material-roughness={1} {...props} dispose={null}>
       {decalTexture && (
         <Decal position={decalPosition} rotation={[0, 0, 0]} scale={decalScale} map={decalTexture} mapAnisotropy={16} />
       )}
     </mesh>
     <Html className='inputt ocultar iputt' position={[0, 0, 0]}>
       <div className='rango'>


       <div className='svg-label' onClick={() => toggleVisibility('scale')}>
         <SizeSVG />
         {visibleControl === 'scale' && (
           <input type="range" min="0.03" max="0.3" step="0.01" value={decalScale} onChange={handleScaleChange} />
         )}
       </div>
       <div className='svg-label' onClick={() => toggleVisibility('x')}>
         <HorizontalSVG />
         {visibleControl === 'x' && (
           <input type="range" min="-1" max="0.8" step="0.01" value={decalPosition[0]} onChange={(event) => handlePositionChange(0, event)} />
         )}
       </div>


       <div className='svg-label' onClick={() => toggleVisibility('y')}>
         <VerticalSVG />
         {visibleControl === 'y' && (
           <input type="range" min="-1" max="0.8" step="0.01" value={decalPosition[1]} onChange={(event) => handlePositionChange(1, event)} />
         )}
       </div>
       <div className='svg-label' onClick={() => toggleVisibility('z')}>
         <DepthSVG />
         {visibleControl === 'z' && (
           <input type="range" min="-1" max="0.8" step="0.01" value={decalPosition[2]} onChange={(event) => handlePositionChange(2, event)} />
         )}
       </div>
       </div>
       <div className='cargarimagen'>
         <button id='btncargarimagen' style={{ background: snap.color }} onClick={handleImageUpload}>Cargar<AiFillPicture size="1.3em" /></button>
       </div>


     </Html>
   </>
 );
 }
  useGLTF.preload('/shirt_baked_collapsed.glb');
  export default App;
 
