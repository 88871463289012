import React from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useSnapshot } from 'valtio';
import { state } from './store';
const BtnVolver = () => {
  const snap = useSnapshot(state);

  const handleClick = () => {
    // Aquí eliminas la clase del elemento
    const element = document.querySelector('.inputt');
    if (element) {
      element.classList.add('ocultar');
    }

    // Aquí cambias el estado
    state.intro = true;

    // Cambiar el estilo del botón
    const button = document.querySelector('.exit');
    if (button) {
      button.style.display = 'none';
     
    }
  };

  return (
    <button className="exit" 
    style={{ background: snap.color }} 
    onClick={handleClick}
    >
    VOLVER
    <AiOutlineArrowLeft size="1.3em" />
  </button>
  );
};

export default BtnVolver;
