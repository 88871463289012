import React from 'react';
import { AiOutlineHighlight } from 'react-icons/ai';
import { useSnapshot } from 'valtio';
import { state } from './store';
const MyButton = () => {
  const snap = useSnapshot(state);

  const handleClick = () => {
    // Aquí eliminas la clase del elemento
    const element = document.querySelector('.ocultar');
    if (element) {
      element.classList.remove('ocultar');
    }

    // Aquí cambias el estado
    state.intro = false;

    // Cambiar el estilo del botón
    const button = document.querySelector('.ocultar');
    if (button) {
      button.style.display = 'block';
     
    }
  };

  return (
    <button
      className="custon"
      style={{ background: snap.color }} // Inicialmente oculto
      onClick={handleClick}
    >
      Comenzar <AiOutlineHighlight size="1.3em" />
    </button>
  );
};

export default MyButton;
