import { Html } from '@react-three/drei'
import { proxy } from 'valtio'

const state = proxy({
  intro: true,
  Html:['SizeSVG'],
  colors: ['#ccc',  '#353934',"#ff0000","#0000ff",
  "#FF5733",
  "#33FF57", // Bright Green
  "#3357FF", // Bold Blue
  "#FF33A1", // Hot Pink
  "#33FFF5", // Aqua Blue
  "#FFDA33", // Vivid Yellow
  "#8333FF", 
  "#FF3333"
 
  ],
  decals: ['react', 'three2', 'pmndrs', 'Logo_zipaquira'],
  color: '#33FFF5',
  decal: 'three2'
})

export { state }
