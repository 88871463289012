import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { state } from './store';
import { useSnapshot } from 'valtio';
import Scale from './component/Scale';
import Uploapimage from './component/uploapimage';
import Sendwhatsapp from './component/sendwhatsapp';
import Ar from './component/Ar';


const Extra = () => {
  const snap = useSnapshot(state);
  const { ref, inView } = useInView({
    triggerOnce: false, // La animación se repetirá cada vez que el componente entre en la vista
    threshold: 0.1 // Se activa cuando el 10% del componente es visible
  });

  return (
    <motion.div
     // ref={ref}
    
    //  animate={inView ? { y: 0, opacity: 1 } : { y: 100, opacity: 0 }}
     // transition={{
      //  type: 'spring',
       // damping: 7,
       // stiffness: 30,
      //  restDelta: 0.001,
     //   duration: 0.6,
     //   delay: 0.2,
     //   delayChildren: 0.2
     // }}
    >
      <div className="extra-section"  style={{ background: snap.color }}>
     
        <h2 className='invisible'></h2>
        <h2 className='comofunciona'>¿Como funciona?</h2>

        <div className='conteiner-estra'>
        <div className="extra-div">
         <Uploapimage />
         
        </div>
        <div className="extra-div">
         < Scale />
        </div>
        <div className="extra-div" >
       <Sendwhatsapp />
        </div>
        <div className="extra-div">
      <Ar />
        </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Extra;
