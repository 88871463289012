/*
Udemy course: https://www.udemy.com/course/react-three-fiber-configurator
*/
import { createRoot } from 'react-dom/client'
import './styles.css'
import { App as Canvas } from './Canvas'
import { Overlay } from './Overlay'
import Scale from './component/Scale'
createRoot(document.getElementById('root')).render(
  <>
    <Canvas />
    <Overlay />
    <Scale />
  </>
)
