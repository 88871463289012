import React from 'react';
import Lottie  from 'lottie-react';
import animationData from '../sendvideo.json'

const Ar = () => {
  return (
    <div style={styles.card}>
      <Lottie
        animationData={animationData}
        style={styles.animation}
      />
      <div style={styles.content}>
        <h2>Termina el Proceso</h2>
        <p>Envíanos el video o pieza grafica que quieres que aparesca
            al escanear la camiseta.</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: 'auto',
    textAlign: 'center',
  },
  animation: {
    height: '130px',
    width: '130px',
    margin: '0 auto',
  },
  content: {
    marginTop: '16px',
  },
};

export default Ar;
