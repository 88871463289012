import React from 'react';
import Lottie  from 'lottie-react';
import animationData from '../descargar.json'

const Sendwhatsapp = () => {
  return (
    <div style={styles.card}>
      <Lottie
        animationData={animationData}
        style={styles.animation}
      />
      <div style={styles.content}>
        <h2>Descarga y envíala</h2>
        <p>Descargala la imagen y envíanosla utilizando el botón de WhatsApp en la web .</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '16px',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: 'auto',
    textAlign: 'center',
  },
  animation: {
    height: '140px',
    width: '140px',
    margin: '0 auto',
  },
  content: {
    marginTop: '16px',
  },
};

export default Sendwhatsapp;
