import React from "react";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useSnapshot } from "valtio";
import { state } from "./store";




const WhatsAppLink = () => {
  const snap = useSnapshot(state);
  const phoneNumber = "+573195301551"; // Número de teléfono de WhatsApp
  const message = "Hola, me gustaria esta camiseta, te envio la imagen."; // Mensaje predeterminado

  const handleClick = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.location.href = whatsappLink;
  };
  

  return (
    <button className="btnwhatsapp"
      onClick={handleClick}
      style={{ background: snap.color}}

    >
      <AiOutlineWhatsApp size="2em" />
    </button>
  );
};

export default WhatsAppLink;
